// ======================================================================
// IMPORTS
// ======================================================================

import clsx from 'clsx';

import style from './FilterWrap.module.scss';

// ======================================================================
// COMPONENT
// ======================================================================

export const FilterWrap = ({ children, padding = true }) => {
  return <div className={clsx(style.wrap, { [style.wrapPadding]: padding })}>{children}</div>;
};

// ======================================================================
// EXPORT
// ======================================================================

export default FilterWrap;
